<template>
  <div>
    <MobileSnapshot v-if="!isLaptop" />
    <LaptopSanpshot v-else />
  </div>
</template>

<script>
import LaptopSanpshot from "../components/Selfie.vue";
import MobileSnapshot from "../components/mobileView/MobileSnapshot.vue";
export default {
  name: "TestDetails",
  data() {
    return {
      isLaptop: true,
    };
  },
  components: {
    LaptopSanpshot,
    MobileSnapshot,
  },
  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize); // Add event listener for window resize
    console.log("mount");
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize); // Remove event listener before component unmounts
  },
  methods: {
    checkScreenSize() {
      this.isLaptop = window.innerWidth >= 1024;
    },
  },
};
</script>
