<template>
  <div style="height: 100vh;">
    <div v-if="Loaderstatus" class="relativeclass">
      <div class="spinnerstyle">
        <Spinner />
      </div>
    </div>
    <div class="login-main-container">
      <div class="h-100 d-flex align-items-center justify-content-center">
        <div class="login-main-sub-container position-relative p-0">
          <b-row class="m-0 w-100">
            <b-col class="p-0">
              <div
                class="w-100 pt-3 d-flex justify-content-center align-items-baseline"
              >
                <img src="../assets/newimgs/GREYLOGO.svg" alt="" />
              </div>
            </b-col>
          </b-row>
          <b-row class="m-0 w-100 " style="height: 90%;">
            <b-col class="p-0 h-100">
              <div class="testdetails-container h-100 mt-3 overflow-hidden">
                <h1 class="login-rightcont-headtxt">
                  Add your Snapshot
                </h1>
                <div
                  style="height: 90%;overflow-y: auto;padding-right: 10px;overflow-x: hidden;"
                >
                  <h3 class="snapshot-subheading mt-3">
                    This process request you to provide a snapshot of yourself,
                    as well as a snapshot of your ID card
                  </h3>
                  <b-row
                    class="mt-3 overflow-hidden pb-3"
                    style="  height: calc(100vh - 330px);"
                  >
                    <b-col class="h-100">
                      <div
                        class="d-flex flex-column justify-content-center align-items-center h-100"
                      >
                        <div class="selfieHeader mb-3">
                          Provide your snapshot
                        </div>
                        <div class="snapshot-imagebox" style="height: 320px;">
                          <div
                            class="snapshot-user-image"
                            v-if="!initialload"
                          ></div>
                          <div
                            v-show="initialload"
                            class="snapshot-user-image-click"
                          >
                            <video
                              v-show="!isPhotoTaken"
                              style="object-fit: unset; transform: scaleX(-1);"
                              ref="camera"
                              class="w-100 h-100 snapshot-idcard-image"
                              id="selfieCam"
                              autoplay
                              muted
                            ></video>
                            <canvas
                              v-show="isPhotoTaken"
                              style="object-fit: unset; transform: scaleX(-1);"
                              id="photoTaken"
                              ref="canvas"
                              class="h-100 w-100"
                            >
                            </canvas>
                          </div>
                        </div>
                        <div class="position-relative mt-3 bluebtns">
                          <b-button
                            @click="takePhoto"
                            variant="primary"
                            class="d-flex align-items-center"
                          >
                            <img
                              src="../assets/newimgs/snapshot/cameraicon.svg"
                              alt=""
                              class="mr-2"
                            />
                            <b-overlay :show="isProcessing"></b-overlay>

                            <span class="">{{ imageTakenStatus }}</span>
                          </b-button>
                        </div>
                      </div>
                    </b-col>
                    <b-col class="h-100">
                      <div
                        class="d-flex flex-column justify-content-center align-items-center h-100"
                      >
                        <div class="selfieHeader mb-3">
                          Take a snapshot of your ID card
                        </div>
                        <div class="snapshot-imagebox" style="height: 320px;">
                          <div
                            class="snapshot-idcard-image"
                            v-if="!initialload"
                          ></div>
                          <div
                            v-show="initialload"
                            class="snapshot-user-image-click"
                          >
                            <video
                              v-show="!idisPhotoTaken"
                              style="object-fit: unset; transform: scaleX(-1);"
                              id="idCam"
                              ref="idcamera"
                              class="snapshot-idcard-image"
                              autoplay
                            ></video>
                            <canvas
                              v-show="idisPhotoTaken"
                              style="object-fit: unset; transform: scaleX(-1);"
                              id="idphotoTaken"
                              ref="idcanvas"
                              class="h-100 w-100"
                            >
                            </canvas>
                          </div>
                        </div>
                        <div class="position-relative mt-3 bluebtns">
                          <b-button
                            @click="idtakePhoto"
                            variant="primary"
                            class="d-flex align-items-center"
                          >
                            <img
                              src="../assets/newimgs/snapshot/cameraicon.svg"
                              alt=""
                              class="mr-2"
                            />

                            <span class="">{{ idCardImageTakenStatus }}</span>
                          </b-button>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <hr class="mb-0" />
              <div
                class="p-3 d-flex align-items-center justify-content-between bluebtns"
              >
                <b-button
                  variant="outline-primary"
                  @click="$bvModal.show('leavetestmodal')"
                  >Leave Test</b-button
                >
                <b-button variant="primary" @click="sendToS3()"
                  >Continue</b-button
                >
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <b-modal
        id="leavetestmodal"
        size="md"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
        :no-close-on-esc="true"
      >
        <div
          class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
        >
          Alert !
        </div>
        <div
          @click="$bvModal.hide('leavetestmodal')"
          class="modalcloseicon pointer"
        >
          <img
            class="rotate-on-hover"
            src="../assets/newimgs/closeicon.svg"
            alt=""
          />
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
        >
          <div>
            <img src="../assets/newui-img/leavetest.svg" alt="" />
          </div>
          <div class="newui-modal-header mt-4">
            Are you sure you want to leave the test ?
          </div>
          <div class="newui-modal-bodytxt mt-3">
            Please note this action cannot be undone
          </div>
          <div class="bluebtns mt-5">
            <b-button
              class="mr-3 pl-4 pr-4"
              variant="outline-primary"
              @click="$bvModal.hide('leavetestmodal')"
              >Cancel</b-button
            >
            <b-button variant="primary" class="pl-4 pr-4" @click="cancel()"
              >Leave Test</b-button
            >
          </div>
        </div>
      </b-modal>

      <b-modal id="captureerror" size="lg" centered hide-footer hide-header>
        <div
          class="newmodalheadings d-flex align-items-center justify-content-center mt-3"
        >
          Image Capturing Error
        </div>
        <div class="mt-3 newui-modal-subheading text-center">
          Captured image is not accurate
        </div>

        <div class="mt-3 d-flex align-items-center justify-content-center">
          <img src="../assets//newimgs/snapshot/captureerror.svg" alt="" />
        </div>

        <div class="mt-2 ml-3 image-capture-error-font">
          Possible Reasons :
        </div>

        <div class="mt-2 image-capture-error-font">
          <ul class="captureerror-font">
            <li>
              <div class="capture-error-disc mr-2"></div>
              <div class="">
                Please check there shouldn't be any person or object in your
                background
              </div>
            </li>
            <li class="mt-3">
              <div class="capture-error-disc mr-2"></div>

              <div class="">
                You should be close enough to the camera to capture the clear
                face
              </div>
            </li>
            <li class="mt-3">
              <div class="capture-error-disc mr-2"></div>

              <div class="">
                Clean your camera lens
              </div>
            </li>
          </ul>
        </div>

        <div class="d-flex align-items-center justify-content-center mt-4">
          <div class="bluebtns">
            <b-button variant="primary" class="px-5" @click="closemodel()"
              >Okay</b-button
            >
          </div>
        </div>
      </b-modal>
    </div>
    <div v-if="showInLap" class="showInLap">
      <b-modal id="systemcheckmodal" centered hide-footer hide-header size="xl">
        <div
          class="newmodalheadings justify-content-center text-center d-flex align-items-center"
        >
          Enable System Permissions
        </div>
        <div
          @click="$bvModal.hide('systemcheckmodal')"
          class="modalcloseicon pointer"
        >
          <img
            class="rotate-on-hover"
            src="../assets/newimgs/closeicon.svg"
            alt=""
          />
        </div>

        <div class="mt-5 ml-3">
          <b-row>
            <b-col>
              <div class="mt-2 step-subtext">
                1. Please Click on view site information to allow all
                permissions
              </div>
              <div class="mt-2">
                <img src="../assets/images/step1.svg" alt="" />
              </div>
            </b-col>
            <b-col>
              <div class="mt-2 step-subtext">
                2. Please Enable all permissions
              </div>
              <div class="mt-2">
                <img src="../assets/images/step2.svg" alt="" />
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="mt-4 ml-3">
          <b-row>
            <b-col>
              <div class="mt-2 step-subtext">
                3. After enabling Permissions, Reload the page
              </div>
              <div class="mt-2">
                <img src="../assets/images/step3.svg" alt="" />
              </div>
            </b-col>
          </b-row>
        </div>
        <div
          class="bluebtns d-flex align-items-center justify-content-center mt-4"
        >
          <b-button
            variant="primary"
            class="px-5"
            @click="$bvModal.hide('systemcheckmodal')"
            >Okay</b-button
          >
        </div>
      </b-modal>
    </div>
    <b-modal
      id="captureImageModal"
      size="md"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
      :no-close-on-esc="true"
    >
      <div
        class="newmodalheadings mt-3 d-flex align-items-center justify-content-center"
      >
        Alert !
      </div>
      <div
        @click="$bvModal.hide('captureImageModal')"
        class="modalcloseicon pointer"
      >
        <img
          class="rotate-on-hover"
          src="../assets/newimgs/closeicon.svg"
          alt=""
        />
      </div>
      <div
        class="d-flex flex-column align-items-center justify-content-center mt-4 mb-4"
      >
        <div>
          <img src="../assets/newui-img/alertimage.svg" alt="" />
        </div>
        <div class="newui-modal-bodytxt mt-4">
          Please capture both pictures to continue
        </div>
        <div class="bluebtns mt-5">
          <b-button
            class="mr-3 px-5"
            variant="primary"
            @click="$bvModal.hide('captureImageModal')"
            >Okay</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Spinner from "../components/fixedcomponents/Spinner.vue";
import { mapGetters } from "vuex";
import { candidateImage } from "../apiFunction";
import axios from "axios";
export default {
  components: {
    Spinner,
  },
  data() {
    return {
      isProcessing: false,
      showInLap: false,
      Loaderstatus: false,
      isCameraOpen: true,
      isPhotoTaken: false,
      idisCameraOpen: true,
      initialload: false,
      idisPhotoTaken: false,
      capturedImage: null,
      capturedIdimage: null,
      idCardImageTakenStatus: "Capture",
      imageTakenStatus: "Capture",
      topicid: "",
      testid: "",
      userid: "",
    };
  },
  created() {
    history.pushState(location.href, null, location.href);
    window.onpopstate = function() {
      history.go(1);
    };
    setTimeout(() => {
      this.initialload = true;
    }, 500);
    this.askCameraPermission();
    this.createCameraElement();
    this.idcreateCameraElement();
    this.topicid = this.testDetails.topicID;
    this.testid = this.testDetails.testID;
    this.userid = this.userID;
    console.log(this.testDetails);
    if (this.testDetails.config.enableCamera === false) {
      this.closeCameraIfCameraIsNotRequired();
    }
    let width = window.innerWidth;
    if (width > 820) {
      console.log(width);
      this.showInLap = true;
    }
    if (width < 820) {
      this.showInLap = false;
    }
  },
  mounted() {},
  computed: {
    ...mapGetters("sectionVUEX", {
      isLoadingStatus: "getIsLoadingStatus",
      testDetails: "getTestDetails",
      userID: "getUserID",
      userDetials: "getUserDetails",
      uuid: "getUUID",
    }),
  },
  methods: {
    closemodel() {
      this.$bvModal.hide("captureerror");
    },

    closeCameraIfCameraIsNotRequired() {
      console.log("closeCameraIfCameraIsNotRequired");
    },
    cancel() {
      const idCardObj = document.getElementById("idCam");
      idCardObj.srcObject = null;
      const picObj = document.getElementById("selfieCam");
      picObj.srcObject = null;
      window.selfieCamStream.getVideoTracks()[0].stop();
      window.idCamStream.getVideoTracks()[0].stop();
      if (localStorage.getItem("fromCandidate")) {
        window.close("", "_parent", "");
      }
      this.$router.push({
        path: "/",
        query: {
          uuid: this.uuid,
        },
      });
    },
    askCameraPermission() {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
          audio: true,
        })
        .then(
          (res) => console.log(res),
          (err) => {
            console.log(err);
          }
        );
    },
    createCameraElement() {
      const constraints = {
        audio: false,
        video: true,
      };

      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        this.$refs.camera.srcObject = stream;
        window.selfieCamStream = stream;
      });
    },
    idcreateCameraElement() {
      const constraints = {
        audio: false,
        video: true,
      };

      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        this.$refs.idcamera.srcObject = stream;
        window.idCamStream = stream;
      });
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    async takePhoto() {
      try {
        this.isProcessing = true;
        const canvas = this.$refs.canvas;
        const camera = this.$refs.camera;
        const videoWidth = camera.videoWidth;
        const videoHeight = camera.videoHeight;

        canvas.width = videoWidth;
        canvas.height = videoHeight;
        const context = canvas.getContext("2d");
        context.drawImage(camera, 0, 0, videoWidth, videoHeight);
        let src = canvas.toDataURL("image/png");
        let file = this.dataURLtoFile(src, "selfie.png");
        this.capturedImage = file;
        this.isPhotoTaken = true;
        if (this.imageTakenStatus == "Capture") {
          const { CancelToken } = axios;
          const source = CancelToken.source();
          setTimeout(() => {
            source.cancel("Request canceled due to timeout");
          }, 30000);
          let formData = new FormData();
          formData.append("image", file);
          let res = await axios.post(
            "https://proctor.simplifymycareer.com/face_detection/",
            formData,
            {
              cancelToken: source.token,
            }
          );
          this.isProcessing = false;
          console.log(res.data.score * 100);
          if (res.data.score * 100 >= 75) {
            this.isPhotoTaken = true;
            this.imageTakenStatus = "Re-capture";
          } else {
            this.isProcessing = false;
            this.isPhotoTaken = false;
            this.imageTakenStatus = "Capture";
            this.$bvModal.show("captureerror");
          }
        } else {
          this.isProcessing = false;
          this.isPhotoTaken = false;
          this.imageTakenStatus = "Capture";
        }
      } catch (error) {
        this.isProcessing = false;
        this.isPhotoTaken = true;
        this.imageTakenStatus = "Re-capture";
        console.log(error);
      }
    },
    async idtakePhoto() {
      this.idisPhotoTaken = !this.idisPhotoTaken;
      if (this.idisPhotoTaken) {
        this.idCardImageTakenStatus = "Re-capture";
      } else {
        this.idCardImageTakenStatus = "Capture";
      }
      const camera = this.$refs.idcamera;
      const canvas = this.$refs.idcanvas;
      const videoHeight = camera.videoHeight;
      const videoWidth = camera.videoWidth;
      canvas.width = videoWidth;
      canvas.height = videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(camera, 0, 0, videoWidth, videoHeight);
      let src = canvas.toDataURL("image/png");
      let file = this.dataURLtoFile(src, "Idpicture.png");
      this.capturedIdimage = file;
    },
    async sendToS3() {
      const isCameraGranted = await this.checkPermissionGranted("camera");
      const isMicroGranted = await this.checkPermissionGranted("microphone");
      if (!isCameraGranted || !isMicroGranted) {
        this.$bvModal.show("systemcheckmodal");
      } else {
        this.Loaderstatus = true;
        let formData = new FormData();
        let topicId = this.topicid;
        let testID = this.testid;
        formData.append("topicID", topicId);
        formData.append("testID", testID);
        formData.append("groupID", this.testDetails.groupID);
        formData.append("candidateID", this.userID);
        formData.append("candidateImage", this.capturedImage);
        formData.append("candidateImageWithID", this.capturedIdimage);
        formData.append("emailID", this.userDetials.email);
        formData.append("attemptNo", this.testDetails.attemptNo);

        if (
          this.idisPhotoTaken &&
          this.isPhotoTaken &&
          this.isProcessing !== true
        ) {
          candidateImage(formData).then(() => {
            window.selfieCamStream.getVideoTracks()[0].stop();
            window.idCamStream.getVideoTracks()[0].stop();
            if (this.testDetails.config.typingTest) {
              this.Loaderstatus = false;
              this.$router.push({
                path: "/TypingTest",
              });
            } else if (this.testDetails.config.communicationTest) {
              this.Loaderstatus = false;
              this.$router.push({
                path: "/communicationtest",
              });
            } else {
              this.Loaderstatus = false;
              this.$router.push({
                path: "/dosanddonts",
              });
            }
          });
        } else {
          this.Loaderstatus = false;
          this.$bvModal.show("captureImageModal");
        }
      }
    },

    async checkPermissionGranted(key) {
      try {
        const permission = await navigator.permissions.query({ name: key });
        return permission.state === "granted";
      } catch (error) {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 821px) {
  .screenSizeLap {
    display: none;
  }
}

@media only screen and (min-width: 821px) {
  .screenSizeMobile {
    display: none;
  }
}

video {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  /* Safari and Chrome */
  -moz-transform: rotateY(180deg);
  /* Firefox */
}

canvas {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  /* Safari and Chrome */
  -moz-transform: rotateY(180deg);
  /* Firefox */
}
.snapshot-loader {
  position: absolute; /* Position the loader relative to its container */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto; /* Center the loader both horizontally and vertically */
  border: 4px solid #f3f3f3; /* Light gray border */
  border-top: 4px solid #3498db; /* Blue border for animation */
  border-radius: 50%; /* Makes it round */
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite; /* Animation properties */
}

/* Keyframes for the animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.image-capture-error-font {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #7a7a7a;
}

.captureerror-font li {
  /* Default bullets style erased */
  list-style: none;
  display: flex;
  align-items: center;
}

.captureerror-font li div {
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #636363;
}
.capture-error-disc {
  width: 10px;
  height: 10px;
  background: #8c8c8c;
  border-radius: 50px;
}
</style>
